<template>
  <div>
    <v-btn :loading="loading" @click="loadSpaces()">Refresh Spaces</v-btn>
    <SnackBarQueue v-model="messages"></SnackBarQueue>
  </div>
</template>

<script>
import SnackBarQueue from "../../helpers/SnackBarQueue";

export default {
  data() {
    return {
      loading: false,
      messages: [],
    };
  },
  components: {
    SnackBarQueue,
  },
  methods: {
    async loadSpaces() {
      this.loading = true;
      this.axios
        .get(`/contentful/spaces`)
        .then((res) => {
          const spaces = res.data.spaces ? res.data.spaces : [];
          this.$store.commit("setContentfulSpaces", spaces);
          this.messages.push({
            message: `Loaded spaces!`,
            color: "info",
          });
        })
        .catch((err) => {
          this.messages.push({
            message: `Failed loading spaces! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
