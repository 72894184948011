var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"main",attrs:{"fluid":""}},[_c('SnackBarQueue',{model:{value:(_vm.messages),callback:function ($$v) {_vm.messages=$$v},expression:"messages"}}),_c('v-card',{staticClass:"pa-10",attrs:{"outlined":"","flat":""}},[_c('h2',{staticStyle:{"display":"inline"}},[_vm._v("Version Release")]),_c('v-btn',{staticStyle:{"float":"right"},on:{"click":function($event){_vm.getMasterSpace();
        _vm.getModelVersions();}}},[_vm._v("Refresh ")]),_c('v-divider',{staticClass:"horizontal-divider"}),_c('br'),_c('v-alert',{attrs:{"color":"indigo","dark":""}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v(" Release a new content model version. The content model of the selected environment is stored in our 'james-content-model' Git repository and can later be used in 'Upgrade Version'. "),_c('br')],1),_c('strong',[_vm._v("Source Space")]),_c('v-row',{staticClass:"ma-2"},[_c('v-select',{staticStyle:{"width":"50%"},attrs:{"outlined":"","disabled":"","items":_vm.masterSpaceSelection,"item-text":"name","item-value":"id","loading":_vm.loading.spaces},model:{value:(_vm.masterSpace),callback:function ($$v) {_vm.masterSpace=$$v},expression:"masterSpace"}}),_c('v-select',{staticStyle:{"width":"50%"},attrs:{"outlined":"","items":_vm.masterSpaceEnvironments,"item-text":"name","return-object":"","loading":_vm.loading.masterSpaceVersions},model:{value:(_vm.selection.masterEnvironment),callback:function ($$v) {_vm.$set(_vm.selection, "masterEnvironment", $$v)},expression:"selection.masterEnvironment"}})],1),_c('strong',[_vm._v("Release Version")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v("To change the version, you need to update the "),_c('strong',[_vm._v("Model Version Information")]),_vm._v(" entry in the selected environment.")])]),_c('v-row',{staticClass:"ma-2"},[_c('v-text-field',{attrs:{"dense":"","disabled":"","outlined":""},model:{value:(_vm.selection.releaseVersion),callback:function ($$v) {_vm.$set(_vm.selection, "releaseVersion", $$v)},expression:"selection.releaseVersion"}})],1),_c('v-row',{staticClass:"ma-2"},[(_vm.versionAlreadyReleased)?_c('v-alert',{attrs:{"color":"yellow","dark":""}},[_c('v-icon',[_vm._v("mdi-alert")]),_c('span',{staticStyle:{"color":"black"}},[_vm._v(" The version "),_c('strong',[_vm._v(_vm._s(_vm.selection.releaseVersion))]),_vm._v(" was already released by "),_c('strong',[_vm._v(_vm._s(_vm.versionReleasedBy))]),_vm._v(". You can overwrite it by proceeding. Before doing so, please assert that this is necessary and does not break any existing deployments.")])],1):_vm._e()],1),_c('hr',{staticStyle:{"margin":"20px"}}),_c('strong',[_vm._v("Exclude Fields")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v("Fields that will be ignored on every content type.")])]),_c('v-row',{staticClass:"ma-2"},[_c('v-autocomplete',{attrs:{"disabled":_vm.contentModel.length == 0,"outlined":"","items":_vm.contentModelFields,"item-text":"name","multiple":"","return-object":"","clearable":true,"loading":_vm.loading.contentModel},model:{value:(_vm.excludedContentFields),callback:function ($$v) {_vm.excludedContentFields=$$v},expression:"excludedContentFields"}})],1),_c('strong',[_vm._v("Exclude a Field for a Specific Content Type")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v("Fields that will be ignored on the selected Content Type.")])]),_vm._l((_vm.selectedContentTypeForFields),function(contentTypeForFields,cIndex){return [(!contentTypeForFields.deleted)?_c('v-row',{key:cIndex,staticClass:"ma-2"},[_c('v-autocomplete',{attrs:{"disabled":_vm.contentModel.length == 0,"outlined":"","items":_vm.contentModel,"item-text":"name","return-object":"","clearable":true,"loading":_vm.loading.contentModel},model:{value:(contentTypeForFields.contentType),callback:function ($$v) {_vm.$set(contentTypeForFields, "contentType", $$v)},expression:"contentTypeForFields.contentType"}}),_c('v-autocomplete',{attrs:{"disabled":!contentTypeForFields.contentType,"outlined":"","items":contentTypeForFields.contentType ? contentTypeForFields.contentType.fields : [],"item-text":"name","multiple":"","clearable":true,"return-object":"","rules":[_vm.rules.isValid(contentTypeForFields)]},model:{value:(contentTypeForFields.fields),callback:function ($$v) {_vm.$set(contentTypeForFields, "fields", $$v)},expression:"contentTypeForFields.fields"}})],1):_vm._e()]}),_c('strong',[_vm._v("Copy ONLY selected Content Types")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v("Only the selected Content Types will be copied.")])]),_c('v-row',{staticClass:"ma-2"},[_c('v-autocomplete',{attrs:{"disabled":_vm.contentModel.length == 0,"outlined":"","items":_vm.contentModel,"item-text":"name","clearable":true,"multiple":"","return-object":"","loading":_vm.loading.contentModel},model:{value:(_vm.selectedContentTypes),callback:function ($$v) {_vm.selectedContentTypes=$$v},expression:"selectedContentTypes"}})],1),_c('strong',[_vm._v("Exclude selected Content Types")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v("Content Types that will be ignored.")])]),_c('v-row',{staticClass:"ma-2"},[_c('v-autocomplete',{attrs:{"disabled":_vm.contentModel.length == 0,"outlined":"","items":_vm.contentModel,"item-text":"name","multiple":"","return-object":"","clearable":true,"loading":_vm.loading.contentModel},model:{value:(_vm.excludedContentTypes),callback:function ($$v) {_vm.excludedContentTypes=$$v},expression:"excludedContentTypes"}})],1),_c('v-btn',{attrs:{"color":_vm.versionAlreadyReleased ? 'yellow' : 'green',"loading":_vm.loading.migration},on:{"click":function($event){return _vm.releaseModel()}}},[_vm._v(_vm._s(_vm.versionAlreadyReleased ? "Re-Release Now" : "Release Now"))])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }