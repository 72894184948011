<template>
  <v-container class="main" fluid>
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <v-card outlined flat class="pa-10">
      <h2 style="display: inline">Copy Content Model</h2>
      <RefreshSpaces style="float:right"></RefreshSpaces>
      <v-divider class="horizontal-divider" /><br />
      <v-alert color="indigo" dark>
        <v-icon>mdi-information</v-icon>
        This function copies the content model from the source space to the target space. Content types that already exist in the target space and do not exist
        in the source space remain unchanged. Copying a field on a content type that already exists in the target space but is of a different type will not work
        and throw an error.
        <br />
        <a href="/jobs" class="orange--text" target="_blank">Job Overview</a>
      </v-alert>
      <!-- select Spaces -->
      <strong>Source Space</strong>
      <v-row class="ma-2">
        <v-select style="width:50%" outlined :items="spaces" item-text="name" item-value="id" v-model="selection.sourceSpace" />
        <v-select
          style="width:50%"
          :disabled="selection.sourceSpace == null"
          outlined
          :items="sourceEnvs"
          item-text="name"
          value="id"
          v-model="selection.sourceEnv"
        />
      </v-row>
      <strong>Target Space</strong>
      <v-row class="ma-2 mb-5">
        <v-select style="width:50%" outlined :items="spaces" item-text="name" item-value="id" v-model="selection.targetSpace" />
        <v-select
          style="width:50%"
          :disabled="selection.targetSpace == null"
          outlined
          :items="targetEnvs"
          item-text="name"
          value="id"
          v-model="selection.targetEnv"
        />
      </v-row>
      <hr style="margin: 20px" />
      <strong>Exclude Fields</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>Fields that will be ignored on every content type.</span>
      </v-tooltip>
      <v-row class="ma-2">
        <v-autocomplete
          :disabled="contentModel.length == 0"
          outlined
          :items="contentModelFields"
          item-text="name"
          multiple
          return-object
          :clearable="true"
          v-model="excludedContentFields"
        />
      </v-row>
      <strong>Exclude a Field for a Specific Content Type</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>Fields that will be ignored on the selected Content Type.</span>
      </v-tooltip>
      <template v-for="(contentTypeForFields, cIndex) of selectedContentTypeForFields">
        <v-row class="ma-2" v-if="!contentTypeForFields.deleted" :key="cIndex">
          <v-autocomplete
            :disabled="contentModel.length == 0"
            outlined
            :items="contentModel"
            item-text="name"
            return-object
            :clearable="true"
            v-model="contentTypeForFields.contentType"
          />
          <v-autocomplete
            :disabled="!contentTypeForFields.contentType"
            outlined
            :items="contentTypeForFields.contentType ? contentTypeForFields.contentType.fields : []"
            item-text="name"
            multiple
            :clearable="true"
            return-object
            v-model="contentTypeForFields.fields"
            :rules="[rules.isValid(contentTypeForFields)]"
          />
        </v-row>
      </template>
      <strong>Copy ONLY selected Content Types</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>Only the selected Content Types will be copied.</span>
      </v-tooltip>
      <v-row class="ma-2">
        <v-autocomplete
          :disabled="contentModel.length == 0"
          outlined
          :items="contentModel"
          item-text="name"
          :clearable="true"
          multiple
          return-object
          v-model="selectedContentTypes"
        />
      </v-row>
      <strong>Exclude selected Content Types</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>Content Types that will be ignored.</span>
      </v-tooltip>
      <v-row class="ma-2">
        <v-autocomplete
          :disabled="contentModel.length == 0"
          outlined
          :items="contentModel"
          item-text="name"
          multiple
          return-object
          :clearable="true"
          v-model="excludedContentTypes"
        />
      </v-row>
      <v-checkbox class="ma-2" v-model="backupTargetSpace" :label="`Make a backup of the target space`"></v-checkbox>
      <v-btn class="primary-button" dark @click="migrateModel()" :loading="loading.migration">Copy Content Model</v-btn>
      <div class="text-center" v-if="loading.migration">
        <v-progress-circular :rotate="360" :size="100" :width="15" :value="jobProgress" color="teal"> {{ jobProgress }}% </v-progress-circular>
      </div>
    </v-card>
    <v-card class="mt-2" outlined flat v-if="jobMessages && jobMessages.length > 0">
      <v-row class="ma-2" v-for="(jobLog, mIndex) of jobMessages" :key="mIndex">
        <p :style="/^error/i.test(jobLog) ? 'color: red' : 'color: blue'">{{ jobLog }}</p>
      </v-row>
    </v-card>
    <br />
    <VueJsonViewer :value="result" :expand-depth="5" boxed v-if="result"></VueJsonViewer>
    <v-card outlined flat> </v-card>
  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue";
import VueJsonViewer from "vue-json-viewer";
import RefreshSpaces from "./helpers/RefreshSpaces.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      messages: [],
      loading: {
        spaces: false,
        migration: false,
        content: false,
      },
      selection: {
        sourceSpace: null,
        sourceEnv: null,
        targetSpace: null,
        targetEnv: null,
      },

      result: null,
      pollJob: null,
      jobProgress: 0,
      contentModel: [],
      contentModelFields: [],
      selectedContentTypes: null,
      excludedContentTypes: null,
      excludedContentFields: null,
      jobMessages: null,
      selectedContentTypeForFields: [
        {
          contentType: null,
          fields: [],
        },
      ],
      rules: {
        isValid: (v) => v.valid || "Item is required",
      },
      backupTargetSpace: true,
    };
  },
  components: {
    SnackBarQueue,
    RefreshSpaces,
    VueJsonViewer,
  },
  computed: {
    ...mapState({
      spaces: (state) => state.contentfulSpaces,
    }),
    sourceEnvs() {
      if (this.selection.sourceSpace) {
        let space = this.spaces.find((space) => this.selection.sourceSpace === space.id);
        if (space && space.environments) return space.environments;
      }
      return [];
    },
    targetEnvs() {
      if (this.selection.targetSpace) {
        let space = this.spaces.find((space) => this.selection.targetSpace === space.id);
        if (space && space.environments) return space.environments;
      }
      return [];
    },
  },
  watch: {
    "selection.sourceSpace"() {
      this.selection.sourceEnv = null;
    },
    "selection.targetSpace"() {
      this.selection.targetEnv = null;
    },
    selectedContentTypeForFields: {
      deep: true,
      handler(val) {
        let notDeletd = val.filter((x) => !x.deleted);
        let empty = notDeletd.find((x) => x.contentType == null && x.fields.length == 0);
        let notEmpty = notDeletd.filter((x) => x.contentType != null && x.fields.length != 0);
        if (empty == null && notEmpty.length == notDeletd.length) {
          this.addToContentTypeForFields();
        }

        let count = 0;
        notDeletd.forEach((e) => {
          if (e.contentType == null && e.fields.length > 0) {
            e.fields = [];
          }
          e.valid = (e.contentType == null && e.fields.length == 0) || (e.contentType != null && e.fields.length > 0);

          if (notDeletd.length == 1) {
            e.deleted = false;
          } else if (e.contentType == null && e.fields.length == 0 && count != notDeletd.length - 1) {
            e.deleted = true;
          }
          count++;
        });
      },
    },
    "selection.sourceEnv"() {
      if (this.selection.sourceSpace && this.selection.sourceEnv) {
        this.getContentModel();
      } else {
        this.contentModel = [];
        this.contentModelFields = [];
        this.selectedContentTypes = null;
        this.excludedContentTypes = null;
        this.excludedContentFields = null;
        this.selectedContentTypeForFields = [
          {
            contentType: null,
            fields: [],
          },
        ];
      }
    },
  },
  methods: {
    deleteEmpty() {
      if (this.selectedContentTypeForFields.length > 1) {
        this.selectedContentTypeForFields = this.selectedContentTypeForFields.filter((x) => !(!x.created && x.contentType == null && x.fields.length == 0));
      }
    },
    addToContentTypeForFields() {
      this.selectedContentTypeForFields.push({
        contentType: null,
        fields: [],
      });
    },
    async getContentModel() {
      this.loading.content = true;
      this.selectedContentTypes = null;
      this.excludedContentTypes = null;
      this.excludedContentFields = null;
      this.selectedContentTypeForFields = [
        {
          contentType: null,
          fields: [],
        },
      ];
      this.axios
        .get(`/contentful/contentModel?spaceId=${this.selection.sourceSpace}&environmentId=${this.selection.sourceEnv}`)
        .then((res) => {
          if (res && res.data && res.data.contentModel) {
            this.contentModel = res.data.contentModel.items;
            this.contentModel.sort((a, b) => a.name.localeCompare(b.name));
            let fieldsArr = this.contentModel.map((x) => x.fields.map((f) => f.id));
            let ccArr = [];
            fieldsArr.forEach((e) => {
              ccArr = ccArr.concat(e);
            });
            this.contentModelFields = [...new Set(ccArr)].sort(sorting);
          } else {
            this.contentModel = [];
            this.contentModelFields = [];
          }
          this.loading.content = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error.message);
        });
    },

    migrateModel() {
      let data = {
        sourceSpace: this.selection.sourceSpace,
        sourceEnvironment: this.selection.sourceEnv,
        targetSpace: this.selection.targetSpace,
        targetEnvironment: this.selection.targetEnv,
        selectedContentTypes: this.selectedContentTypes,
        excludedContentTypes: this.excludedContentTypes,
        excludedContentFields: this.excludedContentFields,
        selectedContentTypeForFields: this.selectedContentTypeForFields.filter((x) => !x.deleted),
        backupTargetSpace: this.backupTargetSpace,
      };

      if (!(data.sourceSpace && data.targetSpace && data.sourceEnvironment && data.targetEnvironment)) {
        return this.messages.push("Please select Source and Target Space first!");
      }

      if (data.sourceSpace === data.targetSpace && data.sourceEnvironment === data.targetEnvironment) {
        return this.messages.push({
          message: "Source and target are the same. Please change your selection!",
          color: "error",
        });
      }

      this.loading.migration = true;
      this.result = null;

      this.axios
        .post("/contentful/migrate-model", data)
        .then((res) => {
          this.pollJob = setInterval(() => {
            this.axios
              .get(`/contentful/get-job/`, {
                params: {
                  jobId: res.data.jobId,
                },
              })
              .then((res) => {
                // job finished AND data is returned AND this is the first response
                if (res.data.progress === 100 && (res.data.failed || res.data.content_types || res.data.failed_content_types) && this.result == null) {
                  clearInterval(this.pollJob);
                  this.loading.migration = false;
                  this.jobProgress = 0;
                  if (res.data.logs) {
                    this.jobMessages = res.data.logs;
                  }
                  if (res.data.failed) {
                    this.messages.push({
                      message: `Failed copying model! ${res.data.message}`,
                      color: "error",
                    });
                  } else {
                    this.result = {
                      copied_content_types: res.data.content_types,
                      failed_content_types: res.data.minor_errors,
                    };
                    this.messages.push({ message: "Successfully copied model!", color: "success" });
                  }
                } else {
                  if (res.data.logs) {
                    this.jobMessages = res.data.logs;
                  }
                  this.jobProgress = res.data.progress;
                }
              })
              .catch((err) => {
                clearInterval(this.pollJob);
                this.loading.migration = false;
                if (res.data.logs) {
                  this.jobMessages = res.data.logs;
                }
                this.jobProgress = 0;
                this.messages.push({
                  message: `Failed copying model! ${err.response ? err.response.data.message : err.message}`,
                  color: "error",
                });
                console.error(err);
              });
          }, 1000);
        })
        .catch((err) => {
          clearInterval(this.pollJob);
          this.loading.migration = false;
          this.messages.push({
            message: `Failed copying model! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        });
    },
  },

  mounted() {},
};
export const sorting = (a, b) => {
  if (a.name == undefined || b.name == undefined) return (a > b) - (a < b);
  else return (a.name > b.name) - (a.name < b.name);
};
</script>

<style lang="scss" scoped>
.customer {
  position: relative;
}

.content_field_box {
  background-color: #7ecead61;
}

.inset {
  margin-left: 40px;
}
</style>
