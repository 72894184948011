<template>
  <v-container class="main" fluid>
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <v-card outlined flat class="pa-10">
      <h2 style="display: inline">Edit Validations</h2>
      <RefreshSpaces style="float:right"></RefreshSpaces>
      <v-divider class="horizontal-divider" /><br />
      <v-alert color="indigo" dark>
        <v-icon>mdi-information</v-icon>
        Insert or Update content type validations such as channels and canonical
      </v-alert>
      <v-row class="ma-2">
        <strong>Source Space</strong>
      </v-row>
      <v-row class="ma-2">
        <v-select style="width:50%" outlined :items="spaces" item-text="name" item-value="id" v-model="sourceSpace" />
        <v-select style="width:50%" :disabled="sourceSpace == null" outlined :items="sourceEnvs" item-text="name" value="id" v-model="sourceEnv" />
      </v-row>
      <v-row class="ma-2">
        <strong>Validations</strong>
      </v-row>
      <template v-for="(validation, index) of validations">
        <v-row class="ma-2" :key="index">
          <!-- Combobox for Adding/Removing Items -->
          <v-combobox v-model="validation.inValues" multiple chips clearable deletable-chips :label="validation.fieldId" hint="Press Enter to add"></v-combobox>
        </v-row>
      </template>
      <v-row class="ma-2">
        <strong>Content Types</strong>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
          </template>
          <span>Leave empty to update all content types</span>
        </v-tooltip>
      </v-row>
      <v-row class="ma-2">
        <ContentTypeSelection
          :spaceId="sourceSpace"
          :envId="sourceEnv"
          :multiple="true"
          @changedSelection="(v) => (contentTypeIds = v)"
          style="width:50%"
        ></ContentTypeSelection>
      </v-row>
      <v-btn class="ma-2 primary-button" dark @click="upsertValidations()" :loading="loading">Update Validations</v-btn>
    </v-card>
  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue.vue";
import RefreshSpaces from "./helpers/RefreshSpaces.vue";
import ContentTypeSelection from "./helpers/ContentTypeSelection.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      messages: [],
      loading: false,
      sourceSpace: null,
      sourceEnv: null,
      contentTypeIds: [],
      availableContentTypes: [],
      validations: [
        { fieldId: "channels", inValues: [] },
        { fieldId: "canonical", inValues: [] },
      ],
    };
  },
  components: {
    SnackBarQueue,
    RefreshSpaces,
    ContentTypeSelection,
  },
  computed: {
    ...mapState({
      spaces: (state) => state.contentfulSpaces,
    }),
    sourceEnvs() {
      if (this.sourceSpace) {
        let space = this.spaces.find((space) => this.sourceSpace === space.id);
        if (space && space.environments) return space.environments;
      }
      return [];
    },
  },
  watch: {
    sourceSpace() {
      this.sourceEnv = null;
    },
  },

  methods: {
    upsertValidations() {
      if (!(this.sourceSpace && this.sourceEnv)) {
        return this.messages.push("Please select Source and Target Space first!");
      }

      if (this.validations.every((v) => v.inValues.length == 0)) return this.messages.push("Please add some validations!");

      this.loading = true;
      this.result = null;

      this.axios
        .post("/contentful/upsert-validations", {
          spaceId: this.sourceSpace,
          envId: this.sourceEnv,
          validations: this.validations,
          contentTypeIds: this.contentTypeIds,
        })
        .then((res) => {
          console.log(res);
          this.messages.push({ message: "Successfully updated content type validations!", color: "success" });
        })
        .catch((err) => {
          clearInterval(this.pollJob);
          this.messages.push({
            message: `Failed listing entries! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
