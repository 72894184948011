<template>
  <v-container class="main" fluid>
    <v-row class="ma-4">
      <v-col cols="10">
        <h1>Changelog</h1>
      </v-col>
      <v-col cols="2">
        <v-btn color="#3c2196" class="white--text" href="/jobs" target="_blank">Job Overview</v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-4"> </v-row>
    <v-row class="ma-2">
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.2.1</v-card-title>
        <v-card-text>
          <ul>
            <li>Added <strong>List Entries</strong> and <strong>Edit Validations</strong> Features</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.2.0</v-card-title>
        <v-card-text>
          <ul>
            <li>Added <strong>EMMA Promo Codes</strong> Feature</li>
          </ul>
        </v-card-text>
      </v-card>

      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.1.10</v-card-title>
        <v-card-text>
          <ul>
            <li>Added <strong>Ignore Duplicates</strong> checkbox to CSV Importer</li>
            <li>Increased CSV Importer file size limit to 10 MB</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.1.9</v-card-title>
        <v-card-text>
          <ul>
            <li>Add script selection to Run Script</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.1.8</v-card-title>
        <v-card-text>
          <ul>
            <li>Add delete option to User Mass Edit</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.1.7</v-card-title>
        <v-card-text>
          <ul>
            <li>Add support for ContentHub 2.0</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.1.6</v-card-title>
        <v-card-text>
          <ul>
            <li>Updated Contentful <strong>Copy Entries</strong> to ignore unchanged entries</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.1.5</v-card-title>
        <v-card-text>
          <ul>
            <li>Added Braze Tab <strong>SCIM</strong> for Adding Dashboard User Accounts</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.1.4</v-card-title>
        <v-card-text>
          <ul>
            <li>Added <strong>Existing Entry Strategy</strong> option to Copy Entries</li>
            <li>Stopped Snackbar from disappearing automatically</li>
            <li>Fixed Compare Model bugs</li>
            <li>Improved Upgrade Version to not stop on minor errors</li>
            <li>Improved loading screen to not block entire page</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.1.3</v-card-title>
        <v-card-text>
          <ul>
            <li>Added version info button to Upgrade Version</li>
            <li>Fixed bug where you would always get redirected to the login page after a page refresh</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.1.2</v-card-title>
        <v-card-text>
          <ul>
            <li>Addition of Contentful Feature 'Release Version'</li>
            <ul>
              <li>Releasing a content model version stores the model in the 'james-content-model' Git repository</li>
            </ul>
            <li>Addition of Contentful Feature 'Upgrade Version'</li>
            <ul>
              <li>Upgrading a Contentful environment by selecting a version from the released versions in the Git repository</li>
            </ul>
          </ul>
        </v-card-text>
      </v-card>
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.1.1</v-card-title>
        <v-card-text>
          <ul>
            <li>Update Contentful Feature 'Compare Models'</li>
            <ul>
              <li>Add support for default values</li>
              <li>Improve describing differences</li>
            </ul>
          </ul>
        </v-card-text>
      </v-card>
      <v-card class="ma-2" max-width="400">
        <v-card-title>Version 2.1.0</v-card-title>
        <v-card-text>
          <ul>
            <li>Update Contentful Feature 'Migration Script'</li>
            <ul>
              <li>Add support for regular scripts in addition to migration scripts</li>
              <li>Rename to 'Run Script'</li>
            </ul>
          </ul>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Dashboard",
  methods: {
    openJobsOverview() {
      this.$router.go("/jobs");
    },
  },
};
</script>

<style scoped>
.main {
  height: 100%;
  width: 100%;
  padding: 20px;
  margin: 20px;
}
</style>
