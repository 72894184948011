<template>
  <v-container class="main" fluid>
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <!-- Model Version Info DIALOG -->
    <v-dialog v-if="dialog" v-model="dialog" transition="dialog-bottom-transition" max-width="600px">
      <v-card>
        <v-card-title class="text-center"> Content Model Version {{ selection.modelVersion.version }} </v-card-title>
        <v-card-subtitle class="mt-1">
          released <strong>{{ new Date(selection.modelVersion.releaseDate).toLocaleString("de-AT") }}</strong>
          <br />
          by <strong>{{ selection.modelVersion.releasedBy }}</strong>
          <br />
          based on environment <strong>{{ selection.modelVersion.envName }}</strong>
        </v-card-subtitle>
        <v-card-text>
          <h1 class="text-h4">Configuration</h1>
          <br />
          <h2 class="text-h6">Excluded Fields</h2>
          <div v-html="excludedContentFields"></div>
          <h2 class="text-h6">Selected ContentTypes</h2>
          <div v-html="selectedContentTypes"></div>
          <h2 class="text-h6">Excluded ContentTypes</h2>
          <div v-html="excludedContentTypes"></div>
          <h2 class="text-h6">Excluded Fields for Specific ContentTypes</h2>
          <div v-html="selectedContentTypeForFields"></div>
          <br />
          <h1 class="text-h4">Release Notes</h1>
          <br />
          <div v-html="releaseNotes"></div>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card outlined flat class="pa-10">
      <h2 style="display: inline">Version Upgrade</h2>
      <RefreshSpaces style="float:right"></RefreshSpaces>
      <v-divider class="horizontal-divider" /><br />
      <v-alert color="indigo" dark>
        <v-icon>mdi-information</v-icon>
        Upgrade the content model of the selected target environment to the selected release version. The upgrade is done stepwise, where every version between
        the currently installed version in the target environment and the selected release version consitutes one step.
        <br />
      </v-alert>
      <!-- select Spaces -->
      <strong>Target Space</strong>
      <v-row :class="['ma-2', { 'mb-4': !targetVersion }]">
        <v-select style="width:50%" outlined :items="spaces" item-text="name" item-value="id" v-model="selection.targetSpace" hide-details />
        <v-select
          style="width:50%"
          :disabled="selection.targetSpace == null || loading.targetModelVersion"
          outlined
          :items="targetEnvs"
          item-text="name"
          value="id"
          v-model="selection.targetEnv"
          hide-details
          :loading="loading.targetModelVersion"
        />
      </v-row>
      <template v-if="selection.targetEnv">
        <strong>Currently Installed Version</strong>
        <v-text-field class="ma-2 mb-2" dense disabled outlined v-model="targetVersion" />
      </template>
      <strong>Content Model Versions</strong>
      <v-row class="ma-2">
        <v-select
          style="width:50%"
          outlined
          :items="modelVersions"
          item-text="version"
          return-object
          v-model="selection.modelVersion"
          :loading="loading.contentModelVersions"
        />
        <div style="width:50%" class="pl-2">
          <v-btn class="secondary-button" v-if="selection.modelVersion" @click="($event) => (dialog = true)">Version Info</v-btn>
        </div>
      </v-row>
      <v-checkbox class="ma-2" v-model="backupTargetSpace" :label="`Make a backup of the target space`"></v-checkbox>
      <v-row>
        <v-btn
          class="primary-button"
          dark
          @click="upgradeModel()"
          :loading="loading.upgrading"
          :disabled="!checkVersions() || existsJobEnv || loading.upgrading"
          >Upgrade Now</v-btn
        >
        <v-spacer />
        <v-btn class="secondary-button" dark v-if="jobId && !loading.upgrading" @click="getJob(jobId, null)" :loading="loading.upgrading"
          >Get Job Progress</v-btn
        >
        <v-spacer />
        <v-btn class="red-button" dark v-if="jobId && false" @click="cancelJob(jobId)" :loading="loading.cancelling">Cancel Job</v-btn>
      </v-row>
      <div class="text-center" v-if="loading.upgrading">
        <v-progress-circular :rotate="360" :size="100" :width="15" :value="jobProgress" color="teal"> {{ jobProgress }}% </v-progress-circular>
      </div>
    </v-card>
    <v-card class="mt-2" outlined flat v-if="jobMessages && jobMessages.length > 0">
      <v-row class="ma-2" v-for="(jobLog, mIndex) of jobMessages" :key="mIndex">
        <p :style="/^error/i.test(jobLog) ? 'color: red' : 'color: blue'">{{ jobLog }}</p>
      </v-row>
    </v-card>
    <br />
    <VueJsonViewer :value="result" :expand-depth="5" boxed v-if="result"></VueJsonViewer>
  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue";
import semver from "semver";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import VueJsonViewer from "vue-json-viewer";
import RefreshSpaces from "./helpers/RefreshSpaces.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      messages: [],
      modelVersions: [],

      targetVersion: null,
      loading: {
        contentModelVersion: false,
        targetModelVersion: false,
        upgrading: false,
        cancelling: false,
      },
      targetSpaceVersions: [],
      pollJob: null,
      jobProgress: 0,
      jobMessages: null,
      existsJobEnv: false,
      jobId: null,
      selection: {
        targetSpace: null,
        targetEnv: null,
        modelVersion: null,
        releaseVersion: null,
      },
      backupTargetSpace: true,
      releaseNotes: "",
      dialog: false,
      result: null,
    };
  },
  components: {
    SnackBarQueue,
    VueJsonViewer,
    RefreshSpaces,
  },
  watch: {
    "selection.targetSpace"() {
      this.selection.targetEnv = null;
      this.targetVersion = null;
      if (this.selection.targetSpace) this.getTargetSpaceVersion(this.selection.targetSpace);
    },
    "selection.targetEnv"() {
      if (this.selection.targetEnv && this.targetSpaceVersions.length > 0) {
        this.targetVersion = this.targetSpaceVersions.find((x) => x.envId == this.selection.targetEnv)?.version;
      } else {
        this.targetVersion = null;
      }
      this.loading.upgrading = false;
      this.existsJob();
    },
    "selection.modelVersion"() {
      if (this.selection.modelVersion) {
        this.releaseNotes = documentToHtmlString(this.selection.modelVersion.releaseNotes);
        this.selection.releaseVersion = this.selection.modelVersion.version;
      }
    },
  },
  computed: {
    ...mapState({
      spaces: (state) => state.contentfulSpaces,
    }),
    excludedContentFields() {
      if (!this.selection.modelVersion?.options.excludedContentFields?.length) return "";
      return "<ul>" + this.selection.modelVersion.options.excludedContentFields.map((f) => "<li>" + f + "</li>").join("") + "</ul>";
    },
    selectedContentTypes() {
      if (!this.selection.modelVersion?.options.selectedContentTypes?.length) return "";
      return "<ul>" + this.selection.modelVersion.options.selectedContentTypes.map((f) => "<li>" + f.name + "</li>").join("") + "</ul>";
    },
    excludedContentTypes() {
      if (!this.selection.modelVersion?.options.excludedContentTypes?.length) return "";
      return "<ul>" + this.selection.modelVersion.options.excludedContentTypes.map((f) => "<li>" + f.name + "</li>").join("") + "</ul>";
    },
    selectedContentTypeForFields() {
      if (!this.selection.modelVersion?.options.selectedContentTypeForFields?.length) return "";
      return (
        "<ul>" +
        this.selection.modelVersion.options.selectedContentTypeForFields
          .filter((v) => v.contentType)
          .map((t) => "<li><strong>" + t.contentType.name + "</strong>: " + t.fields.map((f) => f.name).join(", ") + "</li>")
          .join("") +
        "</ul>"
      );
    },
    targetEnvs() {
      if (this.selection.targetSpace) {
        let space = this.spaces.find((space) => this.selection.targetSpace === space.id);
        if (space && space.environments) return space.environments;
      }
      return [];
    },
  },
  beforeDestroy() {
    clearInterval(this.pollJob);
  },
  methods: {
    checkVersions() {
      if (!this.selection.releaseVersion || !this.targetVersion) {
        return false;
      }
      return semver.lt(this.targetVersion, this.selection.releaseVersion);
    },
    getNum(val) {
      return parseInt(val);
    },
    async getTargetSpaceVersion(spaceId) {
      this.loading.targetModelVersion = true;
      this.targetVersion = null;
      this.targetSpaceVersions = [];
      this.axios
        .get(`/contentful/content-version-entries?spaceId=${spaceId}`)
        .then((res) => {
          if (res && res.data && res.data.length > 0) {
            this.targetSpaceVersions = res.data;
          }
        })
        .catch((err) => {
          this.messages.push({
            message: `Failed loading version space entries! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        })
        .finally(() => {
          this.loading.targetModelVersion = false;
        });
    },
    async getModelVersions() {
      this.loading.contentModelVersions = true;
      this.axios
        .get(`/contentful/content-model-versions`)
        .then(async (res) => {
          if (res && res.data) {
            this.modelVersions = res.data;
          }
        })
        .catch((err) => {
          this.messages.push({
            message: `Failed loading master space! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        })
        .finally(() => (this.loading.contentModelVersions = false));
    },
    async existsJob() {
      this.axios
        .get(`/contentful/exists-job?spaceId=${this.selection.targetSpace}&environmentId=${this.selection.targetEnv}`)
        .then(async (res) => {
          if (res) {
            this.existsJobEnv = typeof res.data != "string";
            if (this.existsJobEnv) {
              this.jobId = res.data.id;
              this.messages.push({
                message: `Job for this Environment already exists!`,
                color: "error",
              });
            } else {
              this.jobId = null;
            }
          }
        })
        .catch((err) => {
          this.messages.push({
            message: `Failed loading master space! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        });
    },
    async getJob(jobId, res) {
      this.loading.upgrading = true;
      this.pollJob = setInterval(() => {
        this.axios
          .get(`/contentful/get-job/`, {
            params: {
              jobId: jobId,
            },
          })
          .then((res) => {
            if ((res.data.progress === 100 || res.data.failed) && this.result == null) {
              clearInterval(this.pollJob);
              this.loading.upgrading = false;
              this.jobProgress = 0;
              if (res.data.logs) {
                this.jobMessages = res.data.logs;
              }
              if (res.data.failed) {
                this.messages.push({
                  message: `Failed copying model! ${res.data.message}`,
                  color: "error",
                });
                this.result = res.data;
              } else {
                this.messages.push({ message: res.data.message, color: "success" });
              }
              this.result = res.data.result;
            } else {
              if (res.data.logs) {
                this.jobMessages = res.data.logs;
              }
              this.jobProgress = res.data.progress;
            }
          })
          .catch((err) => {
            clearInterval(this.pollJob);
            if (res && res.data.logs) {
              this.jobMessages = res.data.logs;
            }
            this.loading.upgrading = false;
            this.jobProgress = 0;
            this.messages.push({
              message: `Failed copying model! ${err.response ? err.response.data.message : err.message}`,
              color: "error",
            });
            console.error(err);
            this.result = err.response.data;
          });
      }, 1000);
    },
    async cancelJob(jobId) {
      this.loading.cancelling = true;
      this.axios
        .get(`/contentful/cancel-job`, {
          params: {
            jobId: jobId,
          },
        })
        .then(async (res) => {
          if (res.data) {
            clearInterval(this.pollJob);
            this.loading.upgrading = false;
            this.messages.push({
              message: `Job cancelled successfully!`,
              color: "success",
            });
          }
        })
        .catch((err) => {
          this.messages.push({
            message: `Failed cancelling job! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        })
        .finally(() => (this.loading.cancelling = false));
    },
    async upgradeModel() {
      this.loading.upgrading = true;
      this.result = null;
      this.selection.targetVersion = this.targetVersion;
      this.axios
        .post(`/contentful/upgrade-version`, { ...this.selection, backupTargetSpace: this.backupTargetSpace })
        .then(async (res) => {
          this.jobId = res.data.jobId;
          await this.getJob(res.data.jobId, res);
        })
        .catch((err) => {
          clearInterval(this.pollJob);
          this.messages.push({
            message: `Failed upgrading new version! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
          this.loading.upgrading = false;
        });
    },
  },

  created() {
    this.getModelVersions();
  },
};
</script>

<style lang="scss" scoped>
.customer {
  position: relative;
}

.content_field_box {
  background-color: #7ecead61;
}

.inset {
  margin-left: 40px;
}
</style>
