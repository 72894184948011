import Vue from "vue";
import App from "./App.vue";

import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuex from "vuex";
import moment from "moment";
import VueCookies from "vue-cookies";

import Router from "vue-router";
import router from "./utils/router";
import store from "./stores/store.js";

import { Network } from "vue-vis-network";

import { createOidcAuth, SignInType } from "vue-oidc-client/vue2";

// eslint-disable-next-line
const appUrl = config.VUE_APP_HOST;

const keycloakUrl = process.env.VUE_APP_KEYCLOAK_URL;

// SignInType could be Window or Popup
Vue.mainOidc = createOidcAuth(
  "main",
  SignInType.Window,
  appUrl,
  {
    authority: "${keycloakUrl}/auth/realms/MyServices",
    client_id: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    response_type: "code",
    automaticSilentRenew: false,
    metadata: {
      issuer: `${keycloakUrl}/auth/realms/MyServices`,
      authorization_endpoint: `${keycloakUrl}/auth/realms/MyServices/protocol/openid-connect/auth`,
      token_endpoint: `${keycloakUrl}/auth/realms/MyServices/protocol/openid-connect/token`,
      userinfo_endpoint: `${keycloakUrl}/auth/realms/MyServices/protocol/openid-connect/userinfo`,
      end_session_endpoint: `${keycloakUrl}/auth/realms/MyServices/protocol/openid-connect/logout`,
      jwks_uri: `${keycloakUrl}/auth/realms/MyServices/protocol/openid-connect/certs`,
      grant_types_supported: ["authorization_code", "implicit", "refresh_token", "password", "client_credentials"],
      response_types_supported: ["code", "none", "id_token", "token", "id_token token", "code id_token", "code token", "code id_token token"],
      subject_types_supported: ["public", "pairwise"],
      id_token_signing_alg_values_supported: ["RS256"],
      userinfo_signing_alg_values_supported: ["RS256"],
      request_object_signing_alg_values_supported: ["none", "RS256"],
      response_modes_supported: ["query", "fragment", "form_post"],
      token_endpoint_auth_methods_supported: ["private_key_jwt", "client_secret_basic", "client_secret_post", "client_secret_jwt"],
      token_endpoint_auth_signing_alg_values_supported: ["RS256"],
      claims_supported: ["sub", "iss", "auth_time", "name", "given_name", "family_name", "preferred_username", "email"],
      claim_types_supported: ["normal"],
      claims_parameter_supported: false,
      scopes_supported: ["openid", "offline_access", "profile", "email", "address", "phone"],
      request_parameter_supported: true,
      request_uri_parameter_supported: true,
      code_challenge_methods_supported: ["plain", "S256"],
      tls_client_certificate_bound_access_tokens: true,
    },
    scope: "openid profile email",
  },
  console,
  4
);

Vue.mainOidc.events.addUserLoaded(function(user) {
  store.commit("setLoggedInUser", user);
  Vue.$cookies.set("access_token", Vue.mainOidc.accessToken);
  console.log(user);
});

Vue.mainOidc.events.addUserSignedOut(function() {
  store.commit("setLoggedInUser", null);
});

Vue.mainOidc.useRouter(router);

window.mainOidc = Vue.mainOidc;

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(Router);
Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.component("network", Network);
Vue.use(VueCookies);

Vue.axios.defaults.baseURL = `/api`;
Vue.axios.defaults.withCredentials = true;

Vue.filter("formatDateTime", function(value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY hh:mm");
  }
});

Vue.mainOidc.startup().then((ok) => {
  if (ok)
    new Vue({
      vuetify,
      router: router,
      store,
      render: (h) => h(App),
      created: () => {
        store.dispatch("loadCustomers");
      },
    }).$mount("#app");
});
