import Vue from "vue";
import Router from "vue-router";
import Dashboard from "@/components/Dashboard.vue";
import Login from "@/components/Login.vue";
import Contentful from "@/components/contentful/Contentful.vue";
import Journeys from "@/components/journeys/Journeys.vue";
import Skiline from "@/components/skiline/Skiline";
import Customers from "@/components/braze/Customers";
import PromoCodes from "@/components/promoCodes/PromoCodes";

let routes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "*",
    component: Dashboard,
  },
  {
    path: "/contentful",
    component: Contentful,
  },
  {
    path: "/contentful/:activeTab",
    component: Contentful,
    props: true,
  },
  {
    path: "/journeys",
    component: Journeys,
  },
  {
    path: "/skiline",
    component: Skiline,
  },
  {
    path: "/braze",
    component: Customers,
  },
  {
    path: "/braze/:mandator/:activeTab",
    component: Customers,
    props: true,
  },
  {
    path: "/braze/:activeTab",
    component: Customers,
    props: true,
  },
  {
    path: "/promo-codes",
    component: PromoCodes,
    props: true,
  },
];

export default new Router({
  mode: "history",
  routes: routes,
});
