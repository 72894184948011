<template>
  <v-container class="main" fluid>
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <v-card outlined flat class="pa-10">
      <h2 style="display: inline">Copy Content Entries</h2>
      <RefreshSpaces style="float:right"></RefreshSpaces>
      <v-divider class="horizontal-divider" /><br />
      <v-alert color="indigo" dark>
        <v-icon>mdi-information</v-icon>
        This function copies content entries from the source space to the target space. All entries of the selected content types get copied. If a content entry
        with the same ID already exists in the target space, the Existing Entry Strategy gets applied.
        <strong>Ensure that the content types you are about to copy from source and target space match!</strong><br />
        <a href="/jobs" class="orange--text" target="_blank">Job Overview</a>
      </v-alert>
      <!-- select Spaces -->
      <strong>Source Space</strong>
      <v-row class="ma-2">
        <v-select style="width:50%" outlined :items="spaces" item-text="name" item-value="id" v-model="selection.sourceSpace" />
        <v-select
          style="width:50%"
          :disabled="selection.sourceSpace == null"
          outlined
          :items="sourceEnvs"
          item-text="name"
          value="id"
          v-model="selection.sourceEnv"
        />
      </v-row>
      <strong>Target Space</strong>
      <v-row class="ma-2">
        <v-select style="width:50%" outlined :items="spaces" item-text="name" item-value="id" v-model="selection.targetSpace" />
        <v-select
          style="width:50%"
          :disabled="selection.targetSpace == null"
          outlined
          :items="targetEnvs"
          item-text="name"
          value="id"
          v-model="selection.targetEnv"
        />
      </v-row>
      <strong>Content Types</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>All entries of these content types get copied.</span>
      </v-tooltip>
      <v-row class="ma-2">
        <v-autocomplete
          style="width:50%"
          :disabled="selection.sourceEnv == null"
          outlined
          :items="availableContentTypes"
          item-text="name"
          item-value="sys.id"
          v-model="contentTypes"
          multiple
        />
      </v-row>
      <strong>Existing Entry Strategy</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>Strategy for handling existing entries (same ID) in the target space.</span>
      </v-tooltip>
      <v-row class="ma-2">
        <v-select style="width:50%" outlined :items="existingEntryStrategyItems" item-text="text" item-value="id" v-model="existingEntryStrategy" />
      </v-row>
      <strong>Selected Entry IDs</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>Copies only the entries with the following IDs</span>
      </v-tooltip>
      <v-row class="ma-2">
        <v-combobox v-model="entryIds" multiple chips clearable deletable-chips hint="Press Enter to add"></v-combobox>
      </v-row>
      <v-row class="ma-2"> </v-row>
      <v-checkbox class="ml-10" v-model="publishedOnly" :label="`Only copy published entries`"></v-checkbox>
      <v-checkbox class="ml-10" v-model="copyLinkedEntries" :label="`Copy linked entries and assets`"></v-checkbox>
      <v-checkbox class="ml-10" v-model="includeTags" :label="`Include entry tags`"></v-checkbox>
      <v-btn class="primary-button" dark @click="migrateEntries()" :loading="loading.migration">Copy Entries</v-btn>
      <div class="text-center" v-if="loading.migration">
        <v-progress-circular :rotate="360" :size="100" :width="15" :value="jobProgress" color="teal"> {{ jobProgress }}% </v-progress-circular>
      </div>
    </v-card>
    <!-- Scrollable List Container -->
    <div style="max-height: 500px; overflow-y: auto;">
      <v-list>
        <div class="ma-2" v-for="(jobLog, index) of jobMessages" :key="index">
          <v-list-item dense :style="index % 2 == 0 ? 'background-color: lightgrey' : ''"> {{ jobLog }} </v-list-item>
        </div>
      </v-list>
    </div>
    <br />
    <VueJsonViewer :value="result" :expand-depth="5" boxed v-if="result"></VueJsonViewer>
    <v-card outlined flat> </v-card>
  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue";
import VueJsonViewer from "vue-json-viewer";
import RefreshSpaces from "./helpers/RefreshSpaces.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      messages: [],
      loading: {
        spaces: false,
        migration: false,
      },
      selection: {
        sourceSpace: null,
        sourceEnv: null,
        targetSpace: null,
        targetEnv: null,
      },
      contentTypes: [],
      publishedOnly: true,
      copyLinkedEntries: false,
      includeTags: false,
      availableContentTypes: [],

      result: null,
      pollJob: null,
      jobProgress: 0,
      jobMessages: [],
      entryIds: [],
      existingEntryStrategyItems: [
        { text: "Do not copy entry", id: "dontCopy" },
        { text: "Overwrite existing entry", id: "overwrite" },
        { text: "Complement missing values (fields and locales)", id: "complement" },
      ],
      existingEntryStrategy: "dontCopy",
    };
  },
  components: {
    SnackBarQueue,
    RefreshSpaces,
    // Loading,
    VueJsonViewer,
  },
  computed: {
    ...mapState({
      spaces: (state) => state.contentfulSpaces,
    }),
    sourceEnvs() {
      if (this.selection.sourceSpace) {
        let space = this.spaces.find((space) => this.selection.sourceSpace === space.id);
        if (space && space.environments) return space.environments;
      }
      return [];
    },
    targetEnvs() {
      if (this.selection.targetSpace) {
        let space = this.spaces.find((space) => this.selection.targetSpace === space.id);
        if (space && space.environments) return space.environments;
      }
      return [];
    },
  },
  watch: {
    "selection.sourceEnv"() {
      if (this.selection.sourceSpace && this.selection.sourceEnv) {
        this.getContentModel();
      }
    },
  },

  methods: {
    async getContentModel() {
      this.loading.content = true;
      this.axios
        .get(`/contentful/contentModel?spaceId=${this.selection.sourceSpace}&environmentId=${this.selection.sourceEnv}`)
        .then((res) => {
          if (res && res.data && res.data.contentModel) {
            this.availableContentTypes = res.data.contentModel.items.sort((a, b) => (a.name > b.name) - (a.name < b.name));
            this.loading.content = false;
          }
        })
        .catch((error) => {
          this.loading.content = false;
          console.error(error.message);
        });
    },
    migrateEntries() {
      let data = {
        sourceSpace: this.selection.sourceSpace,
        sourceEnvironment: this.selection.sourceEnv,
        targetSpace: this.selection.targetSpace,
        targetEnvironment: this.selection.targetEnv,
        contentTypes: this.contentTypes,
        publishedOnly: this.publishedOnly,
        copyLinkedEntries: this.copyLinkedEntries,
        includeTags: this.includeTags,
        entryIds: this.entryIds,
        existingEntryStrategy: this.existingEntryStrategy,
      };

      if (!(data.sourceSpace && data.targetSpace && data.sourceEnvironment && data.targetEnvironment)) {
        return this.messages.push("Please select Source and Target Space first!");
      }

      if (data.sourceSpace === data.targetSpace && data.sourceEnvironment === data.targetEnvironment) {
        return this.messages.push({
          message: "Source and target are the same. Please change your selection!",
          color: "error",
        });
      }

      if (data.contentTypes.length === 0)
        return this.messages.push({
          message: "No content types selected!",
          color: "error",
        });

      this.loading.migration = true;
      this.result = null;

      this.axios
        .post("/contentful/migrate-entries", data)
        .then((res) => {
          this.pollJob = setInterval(() => {
            this.axios
              .get(`/contentful/get-job/`, {
                params: {
                  jobId: res.data.jobId,
                },
              })
              .then((res) => {
                // job finished AND data is returned AND this is the first response
                if (res.data.progress === 100 && (res.data.failed || res.data.content_types || res.data.failed_content_types) && this.result == null) {
                  clearInterval(this.pollJob);
                  this.loading.migration = false;
                  this.jobProgress = 0;
                  this.jobMessages = res.data.logs;
                  if (res.data.failed) {
                    this.messages.push({
                      message: `Failed copying entries! ${res.data.message}`,
                      color: "error",
                    });
                  } else {
                    this.result = {
                      copied_content_types: res.data.content_types,
                      failed_content_types: res.data.minor_errors,
                    };
                    this.messages.push({ message: "Successfully copied entries!", color: "success" });
                  }
                } else {
                  this.jobProgress = res.data.progress;
                  this.jobMessages = res.data.logs;
                }
              })
              .catch((err) => {
                clearInterval(this.pollJob);
                this.loading.migration = false;
                this.jobProgress = 0;
                this.jobMessages = res.data.logs;
                this.messages.push({
                  message: `Failed copying entries! ${err.response ? err.response.data.message : err.message}`,
                  color: "error",
                });
                console.error(err);
              });
          }, 1000);
        })
        .catch((err) => {
          clearInterval(this.pollJob);
          this.loading.migration = false;
          this.messages.push({
            message: `Failed copying entries! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        });
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.customer {
  position: relative;
}

.content_field_box {
  background-color: #7ecead61;
}

.inset {
  margin-left: 40px;
}
</style>
