<template>
  <v-container class="main" fluid>
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <v-card outlined flat class="pa-10">
      <h2 style="display: inline">List Entries</h2>
      <RefreshSpaces style="float:right"></RefreshSpaces>
      <v-divider class="horizontal-divider" /><br />
      <v-alert color="indigo" dark>
        <v-icon>mdi-information</v-icon>
        Download entries as a CSV file
      </v-alert>
      <strong>Source Space</strong>
      <v-row class="ma-2">
        <v-select style="width:50%" outlined :items="spaces" item-text="name" item-value="id" v-model="sourceSpace" />
        <v-select style="width:50%" :disabled="sourceSpace == null" outlined :items="sourceEnvs" item-text="name" value="id" v-model="sourceEnv" />
      </v-row>
      <strong>Content Type</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>All entries of this content type are included.</span>
      </v-tooltip>
      <v-row class="ma-2">
        <ContentTypeSelection
          :spaceId="sourceSpace"
          :envId="sourceEnv"
          :multiple="false"
          @changedSelection="(v) => (contentType = v)"
          style="width:50%"
        ></ContentTypeSelection>
      </v-row>
      <v-btn class="primary-button" dark @click="listEntries()" :loading="loading">List Entries</v-btn>
    </v-card>
  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue.vue";
import RefreshSpaces from "./helpers/RefreshSpaces.vue";
import ContentTypeSelection from "./helpers/ContentTypeSelection.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      messages: [],
      loading: false,
      sourceSpace: null,
      sourceEnv: null,
      contentType: null,
      availableContentTypes: [],
    };
  },
  components: {
    SnackBarQueue,
    RefreshSpaces,
    ContentTypeSelection,
  },
  computed: {
    ...mapState({
      spaces: (state) => state.contentfulSpaces,
    }),
    sourceEnvs() {
      if (this.sourceSpace) {
        let space = this.spaces.find((space) => this.sourceSpace === space.id);
        if (space && space.environments) return space.environments;
      }
      return [];
    },
  },
  watch: {
    sourceSpace() {
      this.sourceEnv = null;
    },
  },

  methods: {
    listEntries() {
      if (!(this.sourceSpace && this.sourceEnv)) {
        return this.messages.push("Please select Source and Target Space first!");
      }

      if (!this.contentType)
        return this.messages.push({
          message: "No content type selected!",
          color: "error",
        });

      this.loading = true;
      this.result = null;

      this.axios
        .get("/contentful/list-entries", {
          params: {
            spaceId: this.sourceSpace,
            envId: this.sourceEnv,
            contentTypeId: this.contentType,
          },
          responseType: "blob", // Important for binary data
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: res.headers["content-type"] });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = this.sourceSpace + "_" + this.sourceEnv + "_" + this.contentType + "_" + new Date().getTime() + ".csv";
          link.click();

          URL.revokeObjectURL(link.href);

          this.messages.push({ message: "Successfully listed entries!", color: "success" });
        })
        .catch((err) => {
          clearInterval(this.pollJob);
          this.messages.push({
            message: `Failed listing entries! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
