<template>
  <v-container class="main" fluid>
    <v-card outlined flat class="pa-10">
      <SnackBarQueue v-model="messages"></SnackBarQueue>
      <h1>EMMA Promo Codes</h1>
      <v-row class="ma-2">
        <v-icon>mdi-information</v-icon>
        <v-alert color="indigo" dark>
          <ul>
            <li>Upload files without header and with one code per line</li>
            <li>Duplicate codes in a list are removed on upload</li>
            <li>Codes are consumed in random order</li>
          </ul>
        </v-alert>
      </v-row>
      <h2>Upload</h2>
      <v-row class="ma-2">
        <v-file-input accept=".csv,.txt" outlined multiple label="Choose Files" v-model="files"> </v-file-input>
        <v-btn class="primary-button" :disabled="files.length == 0" dark @click="uploadCodes()" :loading="loading">Upload</v-btn>
      </v-row>
      <h2>Explore</h2>
      <v-row class="ma-2">
        <v-autocomplete outlined label="Code List" v-model="listName" :items="codeLists" item-value="name">
          <template v-slot:item="{ item }">
            <div>{{ item.name }} ({{ item.size }})</div>
          </template>
          <template v-slot:selection="{ item }">
            <div>{{ item.name }} ({{ item.size }})</div>
          </template>
        </v-autocomplete>
        <v-btn @click="getCodeLists()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-row>
      <v-row class="ma-2 mb-10">
        <v-btn dark @click="showCodes()" :disabled="listName.length == 0" :loading="loading">Show Codes</v-btn>
        <v-btn class="red" dark @click="deleteList()" :disabled="listName.length == 0" :loading="loading">Delete List</v-btn>
      </v-row>
      <div class="ma-2" v-if="codes.length > 0">
        <v-row class="mb-5">
          <h3>{{ listName }} (Remaining: {{ codes.length }})</h3>
          <v-btn class="ml-2" @click="downloadCodes()" :disabled="codes.length == 0" :loading="loading">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-row>
        <!-- Scrollable List Container -->
        <div style="max-height: 500px; overflow-y: auto;">
          <v-list>
            <div v-for="(item, index) in codes" :key="index">
              <v-list-item dense :style="index % 2 == 0 ? 'background-color: lightgrey' : ''"> {{ index }}: {{ item }} </v-list-item>
            </div>
          </v-list>
        </div>
      </div>
      <div v-if="liquidSnippet.length > 0">
        <h3>Liquid Snippet for Braze</h3>
        <v-row class="ma-2">
          <code style="display: block; white-space: pre-wrap;">{{ liquidSnippet }}</code>
          <v-btn @click="copy(liquidSnippet)">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </v-row>
        <v-btn dark @click="getCredentials()">Show Credentials</v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue.vue";

export default {
  components: {
    SnackBarQueue,
  },

  name: "PromoCodes",
  data() {
    return {
      loading: false,
      listName: "",
      messages: [],
      files: [],
      codeLists: [],
      codes: [],
    };
  },
  watch: {
    listName() {
      this.codes = [];
    },
  },

  computed: {
    liquidSnippet() {
      if (!this.listName) return "";
      return (
        // eslint-disable-next-line no-undef
        `{% connected_content ${config.VUE_APP_HOST}api/promo-codes/get-codes?listName=${this.listName}&quantity=1 :basic_auth emma_promo_codes :save promo_codes %}` +
        `\n{% for code in promo_codes %}` +
        `\n{{ code }}` +
        `\n{% endfor %}`
      );
    },
  },
  methods: {
    async copy(str) {
      try {
        await navigator.clipboard.writeText(str);
        this.messages.push({ message: `Copied`, color: "info" });
      } catch (e) {
        this.messages.push({
          message: e.message,
          color: "error",
        });
        console.error(e);
      }
    },
    getCodeLists() {
      this.loading = true;
      this.axios
        .get("/promo-codes/code-lists", {
          params: {},
        })
        .then((res) => {
          this.codeLists = res.data;
          this.messages.push({ message: `Fetched ${this.codeLists.length} code lists`, color: "info" });
        })
        .catch((e) => {
          this.messages.push({
            message: e.message,
            color: "error",
          });
          console.error(e);
        })
        .finally(() => (this.loading = false));
    },
    getCredentials() {
      this.loading = true;
      this.axios
        .get("/promo-codes/get-credentials", {
          params: {},
        })
        .then((res) => {
          this.messages.push({ message: `Username: ${res.data.username}` + ` Password: ${res.data.password}`, color: "info", timeout: 10000 });
        })
        .catch((e) => {
          this.messages.push({
            message: e.message,
            color: "error",
          });
          console.error(e);
        })
        .finally(() => (this.loading = false));
    },
    showCodes() {
      this.loading = true;
      this.axios
        .get("/promo-codes/show-codes", {
          params: {
            listName: this.listName,
          },
        })
        .then((res) => {
          this.codes = res.data;
          this.messages.push({ message: "Loaded codes", color: "info" });
        })
        .catch((e) => {
          this.messages.push({
            message: e.message,
            color: "error",
          });
          console.error(e);
        })
        .finally(() => (this.loading = false));
    },
    downloadCodes() {
      this.axios
        .get("/promo-codes/show-codes", {
          params: {
            listName: this.listName,
          },
        })
        .then((res) => {
          const blob = new Blob([this.codes.join("\n")], { type: res.headers["content-type"] });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = this.listName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((e) => {
          this.messages.push({
            message: e.message,
            color: "error",
          });
          console.error(e);
        })
        .finally();
    },
    addCodes() {
      this.loading = true;
      this.axios
        .get("/promo-codes/add-codes", {
          params: {
            listName: this.listName,
            codes: ["AB123", "DE456"],
          },
        })
        .then((res) => {
          this.messages.push({ message: this.listName + "Codes added: " + res.data.quantity, color: "success" });
        })
        .catch((e) => {
          this.messages.push({
            message: e.message,
            color: "error",
          });
          console.error(e);
        })
        .finally(() => (this.loading = false));
    },
    deleteList() {
      if (!confirm("Delete " + this.listName + " ?")) return;
      this.loading = true;
      this.axios
        .get("/promo-codes/delete-list", {
          params: {
            listName: this.listName,
          },
        })
        .then((res) => {
          this.messages.push({ message: "Deleted " + this.listName, color: "success" });
        })
        .catch((e) => {
          this.messages.push({
            message: e.message,
            color: "error",
          });
          console.error(e);
        })
        .finally(() => (this.loading = false));
    },
    uploadCodes() {
      this.loading = true;
      const bodyFormData = new FormData();
      this.files.forEach((f) => bodyFormData.append("files", f));

      this.axios
        .post("/promo-codes/upload-codes", bodyFormData)
        .then((res) => {
          this.messages.push({ message: `Uploaded ${this.files.length} promo code lists`, color: "success" });
        })
        .catch((e) => {
          this.messages.push({
            message: e.message,
            color: "error",
          });
          console.error(e);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getCodeLists();
  },
};
</script>

<style scoped></style>
