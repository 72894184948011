<template>
  <v-app id="app">
    <!-- Toolbar -->
    <v-app-bar app color="#3c2196" dense dark>
      <v-toolbar-title>JEMMA Admin {{ appVersion }} (Content-Hub 2.0)</v-toolbar-title>
      <button ref="shiftFocus" />
      <v-spacer></v-spacer>

      <!-- Profile -->
      <v-menu offset-y close-on-click v-if="haveUser">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="on" v-bind="attrs">
            <v-icon size="32px">mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>{{ user && user.profile ? user.profile.email : "" }}</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-title style="color: red">LOGOUT</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer app permanent width="175">
      <v-row class="fill-container">
        <v-col class="fill-navigation">
          <br />
          <v-card :class="active === 'dashboard' ? 'navigation-menu-item-active' : 'navigation-menu-item'" flat @click="setActive('dashboard')">
            <div class="navigation-item-wrapper">
              <v-icon size="32px" color="#455A64">mdi-view-dashboard</v-icon><br /><br />
              <v-label>Dashboard</v-label>
            </div>
          </v-card>

          <br />

          <v-card :class="active === 'contentful' ? 'navigation-menu-item-active' : 'navigation-menu-item'" flat @click="setActive('contentful')">
            <div class="navigation-item-wrapper">
              <div
                style="
                    height: 32px;
                    width: 32px;
                    margin-left: auto;
                    margin-right: auto;
                  "
              >
                <svg style="font-size: 24px; fill: currentcolor; width: 40px; height: 40px;" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 23.977 8.069 L 75.99 8.199 L 98.895 56.432 L 76.048 92.8 L 23.426 92.523 L 1.022 43.797 Z M 27.28 86.543 L 72.742 86.782 L 92.061 56.03 L 72.192 14.189 L 27.249 14.077 L 7.846 44.275 Z"
                  />
                  <line x1="4.532" y1="44.241" x2="40.138" y2="15.876" />
                  <path d="M 2.663 41.895 L 38.269 13.53 L 42.007 18.222 L 6.401 46.587 Z" />
                  <line x1="4.532" y1="44.241" x2="39.857" y2="64.548" />
                  <path d="M 38.362 67.149 L 3.037 46.842 L 6.027 41.64 L 41.352 61.947 Z" />
                  <line x1="26.117" y1="89.058" x2="39.857" y2="64.548" />
                  <path d="M 42.474 66.015 L 28.734 90.525 L 23.5 87.591 L 37.24 63.081 Z" />
                  <line x1="74.182" y1="89.299" x2="39.857" y2="64.548" />
                  <path d="M 72.427 91.732 L 38.102 66.981 L 41.612 62.115 L 75.937 86.866 Z" />
                  <line x1="82.571" y1="44.339" x2="39.857" y2="64.548" />
                  <path d="M 83.854 47.051 L 41.14 67.26 L 38.574 61.836 L 81.288 41.627 Z" />
                  <line x1="40.268" y1="16.239" x2="39.857" y2="64.548" />
                  <path d="M 43.268 16.265 L 42.857 64.574 L 36.857 64.522 L 37.268 16.213 Z" />
                  <line x1="40.268" y1="16.239" x2="82.663" y2="44.1" />
                  <path d="M 41.916 13.732 L 84.311 41.593 L 81.015 46.607 L 38.62 18.746 Z" />
                  <line x1="74.215" y1="11.422" x2="82.663" y2="44.1" />
                  <path d="M 79.758 44.851 L 71.31 12.173 L 77.12 10.671 L 85.568 43.349 Z" />
                  <line x1="74.585" y1="89.388" x2="82.663" y2="44.1" />
                  <path d="M 85.616 44.627 L 77.538 89.915 L 71.632 88.861 L 79.71 43.573 Z" />
                  <line x1="95.175" y1="56.092" x2="82.663" y2="44.1" />
                  <path d="M 84.739 41.934 L 97.251 53.926 L 93.099 58.258 L 80.587 46.266 Z" />
                  <line x1="40.268" y1="16.239" x2="74.043" y2="11.436" />
                  <path d="M 39.846 13.269 L 73.621 8.466 L 74.465 14.406 L 40.69 19.209 Z" />
                  <line x1="40.268" y1="16.239" x2="26.144" y2="11.288" />
                  <path d="M 39.276 19.07 L 25.152 14.119 L 27.136 8.457 L 41.26 13.408 Z" />
                </svg>
              </div>
              <br />
              <v-label>Content-Hub</v-label>
            </div>
          </v-card>

          <br />

          <v-card :class="active === 'journeys' ? 'navigation-menu-item-active' : 'navigation-menu-item'" flat @click="setActive('journeys')">
            <div class="navigation-item-wrapper">
              <v-icon size="32px" color="#455A64">mdi-map-marker-path</v-icon><br /><br />
              <v-label>JAMES Journeys</v-label>
            </div>
          </v-card>

          <br />

          <v-card :class="active === 'skiline' ? 'navigation-menu-item-active' : 'navigation-menu-item'" flat @click="setActive('skiline')">
            <div class="navigation-item-wrapper">
              <v-icon size="32px" color="#455A64">mdi-terrain</v-icon><br /><br />
              <v-label>Skiline</v-label>
            </div>
          </v-card>

          <br />

          <v-card :class="active === 'braze' ? 'navigation-menu-item-active' : 'navigation-menu-item'" flat @click="setActive('braze')">
            <div class="navigation-item-wrapper">
              <v-icon size="32px" color="#455A64">mdi-account-group</v-icon><br /><br />
              <v-label>Braze</v-label>
            </div>
          </v-card>

          <br />

          <v-card :class="active === 'promo-codes' ? 'navigation-menu-item-active' : 'navigation-menu-item'" flat @click="setActive('promo-codes')">
            <div class="navigation-item-wrapper">
              <v-icon size="32px" color="#455A64">mdi-barcode-scan</v-icon><br /><br />
              <v-label
                >EMMA <br />
                Promo Codes</v-label
              >
            </div>
          </v-card>
        </v-col>
        <v-divider vertical class="vertical-divider"></v-divider>
      </v-row>
    </v-navigation-drawer>
    <!-- Content -->
    <v-main class="content">
      <v-container class="container" fluid>
        <v-row class="fill-container">
          <v-col class="fill-container">
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "App",
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    haveUser() {
      return Vue.mainOidc.isAuthenticated;
    },
    active() {
      return this.$store.getters.activeMenuItem;
    },
    user() {
      return this.$store.getters.loggedInUser;
    },
  },
  methods: {
    logout() {
      Vue.mainOidc.mgr.removeUser();
      this.$router.push("/login");
    },

    setActive(item) {
      this.$store.commit("setActiveMenuItem", item);
      if (this.$router.currentRoute.fullPath !== `/${item}`) {
        this.$router.push(`/${item}`);
      }
      //shift focus otherwise the background of the selected icon stays grey
      this.$nextTick(function() {
        this.$refs.shiftFocus.focus();
      });
    },
  },

  created() {
    const path = this.$router.currentRoute.fullPath.length > 1 ? this.$router.currentRoute.fullPath.substring(1).split("/")[0] : "dashboard";
    this.$store.commit("setActiveMenuItem", path);
    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401 && error.response.config && !error.response.config.__isRetryRequest) {
          // if you ever get an unauthorized, logout the user
          this.$cookies.remove("access_token");
          this.$cookies.remove("refresh_token");
          // you can also redirect to /login if needed !
          this.$router.push("/login");
        }
        return Promise.reject(error);
      }
    );
    // intercept requests and check if access Token is present
    this.axios.interceptors.request.use(
      (config) => {
        console.log("interceptor", config);
        config.headers = {
          Authorization: "Bearer " + Vue.mainOidc.accessToken,
        };
        return config;
      },
      (error) => Promise.reject(error)
    );
  },
});
</script>

<style>
#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.v-navigation-drawer {
  background-color: #cfd8dc !important;
}

.navigation-item-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.navigation-menu-item {
  width: 100%;
  background-color: transparent !important;
  padding: 3px !important;
  margin: 3px !important;
  text-align: center;
}

.navigation-menu-item-active {
  width: 100%;
  border-top: 2px solid #90a4ae !important;
  border-left: 2px solid #90a4ae !important;
  border-bottom: 2px solid #90a4ae !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  background-color: #ffffff !important;
  padding: 2px !important;
  margin: 2px !important;
  text-align: center;
}

.vertical-divider {
  width: 2px !important;
  min-width: 2px !important;
  background-color: #90a4ae;
}

.horizontal-divider {
  height: 1px !important;
  min-height: 1px !important;
  background-color: #90a4ae;
  margin-top: 20px;
}

.horizontal-divider-dense {
  height: 1px !important;
  min-height: 1px !important;
  background-color: #90a4ae;
  margin-top: 0px;
}

.content {
  height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #ffffff;
}

.container {
  height: 100%;
  padding: 0px !important;
}

.fill-navigation {
  height: 100%;
  width: 100%;
  padding: 0px 0px 10px 10px !important;
  margin: 0px 0px 10px 10px !important;
}

.fill-container {
  height: 100%;
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
}

.primary-button {
  height: 48px !important;
  background: linear-gradient(#3c2196) !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-size: 13pt !important;
  text-transform: uppercase !important;
  letter-spacing: 0px !important;
  font-weight: bold;
  margin-right: 10px;
  box-shadow: none !important;
}

.red-button {
  height: 48px !important;
  background: linear-gradient(0deg, #b71c1c 0%, #d32f2f 100%) !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-size: 13pt !important;
  text-transform: uppercase !important;
  letter-spacing: 0px !important;
  font-weight: bold;
  margin-right: 10px;
  box-shadow: none !important;
}

h1 {
  font-weight: normal;
  font-size: 22pt;
}

h2 {
  font-weight: normal;
  font-size: 18pt;
}

.sub-title {
  font-weight: normal;
  font-size: 10pt;
}

.v-label {
  font-size: 14pt !important;
}

.v-tabs {
  padding-left: 10px;
  padding-top: 30px;
  margin-left: 10px;
}

.v-tab {
  font-size: 13pt !important;
  font-weight: bold !important;
  letter-spacing: 0.1 !important;
}

.no-wrap {
  flex-wrap: nowrap !important;
}
</style>
